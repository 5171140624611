import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { FaFontAwesomeAlt } from "react-icons/fa";

function Footer() {

  return (
    <div id="footer_section">
      {/* Footer */}

      <footer className="w3-center w3-black w3-padding-64 w3-opacity w3-hover-opacity-off">
        <a href="#home" className="w3-button w3-light-grey w3-round-large">
            <FaArrowUp 
           className="w3-margin-right"
          />To the top
        </a>

        <div id="paddedDiv" className="w3-xlarge w3-section">
            <FaFacebook />
            <FaTwitter />
            <FaLinkedin />
        </div>
        <p>
          Copyright {'\u00A9'} 2021 Ambient. All Rights Reserved
          <br></br>
          Ambient are incorporated in Switzerland - number: CHE-459.738.820
          <br></br>
          Switzerland Innovation Park, Lichtstrasse 35, 4056, Basel, Switzerland
          <br></br>
          <br></br>
          <FaEnvelope 
           className="w3-hover-text-blue w3-large w3-margin-right"
          />
          andrew@ambient-design.net
          <FaPhone 
           className="w3-hover-text-blue w3-large w3-margin-left w3-margin-right"
          />+41 79 723 87 89
        </p>
        
        {/* Trigger/Open tooltip */}
        <div class="w3-container">
            <p class="w3-tooltip">Privacy and Cookie Policy &nbsp; <span class="w3-text">(<em>&nbsp;Ambient respects your privacy. &nbsp; We do not use cookies or other trackers on this website &nbsp;</em>)</span></p>
        </div>

        <p>
          with thanks to &nbsp;
          <a
            href="https://www.w3schools.com//default.asp"
            title="w3school.com"
            target="_blank"
            rel="noreferrer"
            className="w3-hover-text-blue"
            >w3school.com</a>
  
             <br></br>
  
              <a
              href="https://reactjs.org/"
              title="React"
              target="_blank"
              rel="noreferrer"
              className="w3-hover-text-blue"
            >React</a> &nbsp;
  
            <FaReact 
             className="w3-hover-text-blue"
            />
  
            &nbsp; and &nbsp;
  
            <a
              href="https://fontawesome.com/"
              title="FontAwesome"
              target="_blank"
              rel="noreferrer"
              className="w3-hover-text-blue"
            >FontAwesome </a> &nbsp;
  
             <FaFontAwesomeAlt 
             className="w3-hover-text-blue"
             />
  
             <br></br>
             <br></br> 
        </p>
        
      </footer>
    </div>
  );
}

export default Footer;
