import React from "react";
import { FaBars } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { FaTh } from "react-icons/fa";
import helpers from "../Helper.js";

function Home() {
  return (
    <div id="home_section">
      <div className="w3-top">
        {/* Navbar (sit on top) */}
        <div className="w3-bar" id="myNavbar">
          <a
            className="w3-bar-item w3-button w3-hover-black w3-hide-medium w3-hide-large w3-right"
            href="javascript:void(0);"
            onClick={() => {
              helpers.toggleFunction();
            }}
            title="Toggle Navigation Menu"
          >
            <FaBars />
            <i className="fa fa-bars"></i>
          </a>
          <a 
            href="#home" 
            className="w3-bar-item w3-button"
            onClick={() => {
              helpers.toggleFunction(1);
            }}
          >
            <FaHome className="w3-margin-right" />
            HOME
          </a>
          <a href="#about" className="w3-bar-item w3-button w3-hide-small">
            <FaUser className="w3-margin-right" />
            ABOUT
          </a>
          <a
            href="#capabilities"
            className="w3-bar-item w3-button w3-hide-small"
          >
            <FaTh className="w3-margin-right" />
            CAPABILITIES
          </a>
          <a href="#contact" className="w3-bar-item w3-button w3-hide-small">
            <FaEnvelope className="w3-margin-right" />
            CONTACT
          </a>
        </div>

        {/* Navbar on small screens */}
        <div
          id="navDemo"
          class="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium"
        >
          <a
            href="#about"
            class="w3-bar-item w3-button"
            onClick={() => {
              helpers.toggleFunction();
            }}
          >
            ABOUT
          </a>
          <a
            href="#capabilities"
            class="w3-bar-item w3-button"
            onClick={() => {
              helpers.toggleFunction();
            }}
          >
            CAPABILITIES
          </a>
          <a
            href="#contact"
            class="w3-bar-item w3-button"
            onClick={() => {
              helpers.toggleFunction();
            }}
          >
            CONTACT
          </a>
        </div>
      </div>

      {/* First Parallax Image with Logo Text */}
      <div className="bgimg-1 w3-display-container w3-opacity-min" id="home">
        <div
          id="line1"
          className="w3-center w3-display-bottommiddle"
          style={{ whiteSpace: "nowrap" }}
        >
          <span className="w3-padding-large w3-black w3-xlarge w3-wide w3-animate-opacity">
            <span className="w3-hide-small">WELCOME TO</span> AMBIENT
          </span>
          <br></br>
          <br></br>
          <span className="w3-center w3-padding-large w3-black w3-xlarge w3-wide">
            imagineering <span className="w3-hide-small">connected</span> health
          </span>
          <br></br>
          <br></br>
          <span className="w3-center w3-padding-large w3-wide w3-animate-opacity">
            scroll down{" "}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Home;
